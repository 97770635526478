.LoginPage {
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #003e55;
   &-container {
      width: 300px;
      border-radius: 15px;
      background-color: white;
      box-shadow: 1px 3px 20px 0px #989898;
   }

   &-content {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px;
      flex-direction: column;
      .logoHeader {
         width: 80px;
         height: 80px;
         object-fit: contain;
      }

      .titleHeader {
         margin: 20px 0;
         color: #646464;
         font-weight: 600;
         font-size: 16px;
         text-transform: uppercase;
      }

      .formLogin {
         display: flex;
         flex-direction: column;

         .btn-form-login{
            margin-top: 20px;
         }
      }
   }
}
