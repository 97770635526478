.ListItemMenu {
   width: 100%;

   &-list {
      width: 100%;
      list-style: none;
      padding: 0;
      margin: 0;
   }

   &-item {
      margin: 10px 0;
      width: 100%;

      .ListItemMenu-itemContainer {
         display: flex;
         flex-direction: row;
         justify-content: space-between;
         align-items: center;
         width: 100%;

         .ListItemMenu-item_left {
            display: flex;
            align-items: center;
            flex-direction: row;

            .ListItemMenu-item_image {
               >img {
                  width: 70px;
                  height: 70px;
                  border-radius: 15px;
               }
            }

            .ListItemMenu-item_info {
               margin-left: 20px;
               display: flex;
               flex-direction: column;
               justify-content: center;

               .itemTitle {
                  font-weight: bold;
               }

               .itemDescription {
                  font-style: italic;
                  font-size: 13px;
               }
            }
         }

         .ListItemMenu-item_right {
            width: 125px;
            display: flex;
         }
      }
   }

   &-select {
      width: 100%;
      margin: 10px 0 !important;
   }
}