.Page {
   display: flex;
   flex-direction: column;
   // width: 100%;
   height: 100vh;
   padding: 20px;

   &-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
   }

   &-content {
      width: 100%;
      height: calc(100% - 100px);
      overflow: scroll;
   }
}
