.Dashboard {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100%;

   > * {
      margin: 0;
   }

   > h2 {
      margin-top: 10px;
   }
}
