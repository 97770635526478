.ChangeImage {
   display: flex;
   flex-direction: column;
   width: 100%;

   .detail-upload-image {
      font-style: italic;
      color: #646464;
      font-size: 14px;
   }
   &-container {
      margin-top: 10px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
   }

   &-preview {
      > img {
         width: 200px;
         height: 100px;
         border-radius: 15px;
         object-fit: cover;
         background-color: #cbcbcb;
      }

      .background {
         width: 200px;
         height: 100px;
         border-radius: 15px;
         background-color: #cbcbcb;
      }
   }

   &-input {
      //   width: 100%;
      margin-left: 20px;
      .wrapper {
         width: 100%;
         height: 100%;
         display: flex;
         align-items: center;
         justify-content: center;

         .file-upload {
            height: 50px;
            width: 50px;
            border-radius: 100px;
            position: relative;

            display: flex;
            justify-content: center;
            align-items: center;

            border: 4px solid #ffffff;
            overflow: hidden;
            background-image: linear-gradient(
               to bottom,
               #2590eb 50%,
               #ffffff 50%
            );
            background-size: 100% 200%;
            transition: all 1s;
            color: #ffffff;
            font-size: 100px;

            input[type='file'] {
               height: 50px;
               width: 50px;
               position: absolute;
               top: 0;
               left: 0;
               opacity: 0;
               cursor: pointer;
            }

            &:hover {
               background-position: 0 -100%;

               color: #2590eb;
            }
         }
      }
   }
}
