.Drawer {
   width: 100%;
   height: 100%;

   * {
      transition: all 600ms ease-in-out;
   }

   &-container {
      width: 100%;
      height: 100%;
      // padding: 5px;
      display: flex;
      flex-direction: column;

      * {
         color: white;
      }

      &.open-drawer {
         // padding: 30px;

         .Drawer-header {
            justify-content: flex-start;
         }

         .Drawer-footer {
            align-items: flex-start;
         }

         .Drawer-navigation {
            .navigationList {
               .navigationItem {
                  margin-left: 20px;

                  &-link {
                     justify-content: flex-start;
                  }
               }
            }
         }
      }

      .Drawer-header {
         height: 100px;
         padding: 0 20px;

         display: flex;
         flex-direction: row;
         align-items: center;
         justify-content: center;

         &_image {
            img {
               width: 50px;
               height: 50px;
               border-radius: 50%;
               object-fit: cover;
            }
         }

         &_user {
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .user-name {
               font-weight: bold;
            }

            .user-email {
               font-size: 12px;
               font-style: italic;
            }
         }
      }

      .Drawer-navigation {
         flex: 1;
         display: flex;
         align-items: center;
         overflow-x: hidden;
         overflow-y: scroll;
         height: calc(100% - 200px);

         .navigationList {
            list-style: none;
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;

            .navigationItem {
               margin-bottom: 20px;
               transition: all 250ms;
               display: flex;
               flex-direction: row;
               flex-wrap: nowrap;
               border-radius: 15px;

               &-link {
                  padding: 10px;
                  text-decoration: none;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  transition: all 600ms ease-in-out;

                  span {
                     margin-left: 20px;
                  }
               }
               &:hover {
                  cursor: pointer;
                  background-color: rgba(0, 0, 0, 0.3);

                  .navigationItem-link {
                     transform: scale(1.03);
                  }
               }
            }
         }
      }
      .Drawer-footer {
         height: 100px;
         padding: 0 20px;

         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;

         .footerItem {
            padding: 10px;
            border: none;
            background-color: transparent;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: row;
            border-radius: 15px;
            outline: none;
            span {
               font-size: 14px;
               margin-left: 20px;
            }

            &:hover {
               cursor: pointer;
               background-color: rgba(0, 0, 0, 0.3);
            }
         }
      }
   }
}
