.Modal {
   position: absolute;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   padding: 50px;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: rgba(0, 0, 0, 0.6);

   &-card {
      width: 700px;
      height: 80vh;
      border-radius: 15px;
      background-color: white;
      box-shadow: 0px 0px 15px 0px #292929;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px;
      &-header {
         width: 100%;
         height: 70px;
         display: flex;
         align-items: center;
         justify-content: space-between;

         .header-left {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
            .title {
               font-size: 20px;
               margin: 0;
               text-transform: uppercase;
            }

            .description {
               font-size: 13px;
            }
         }

         .header-right {
         }
      }

      &-content {
         width: 100%;
         height: 100%;
         overflow-y: auto;
         padding: 20px 0;
      }
      &-footer {
         width: 100%;
         height: 70px;
         display: flex;
         flex-direction: row;
         align-items: center;
         justify-content: flex-end;
      }
   }
}
