@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;700&display=swap');
@import './partials/page';
@import './partials/scrollbars';
/* autoprefixer grid: on */
body,
html {
   margin: 0;
   padding: 0;
   width: 100vw;
   height: 100vh;
}

#root {
   width: 100vw;
   height: 100vh;
}

* {
   font-family: 'Montserrat', sans-serif;
}

.MuiSelect-nativeInput {
   width: auto !important;
}

.MuiCard-root.card-chart {
   height: 400px;
   // padding: 20px;
   margin: 5px;
}

.expand-drawer {
   width: 600px;
   overflow-y: scroll !important;
}

.content-chart-drawer {
   padding: 10px 20px;
   .title-chart-drawer {
      font-weight: bold;
      margin-bottom: 20px;
   }
}
