input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

button {
   border-radius: 50px;
   border: 3px solid #609db7;
   background-color: white;
   color: #609db7;
   font-size: 1.2em;
   cursor: pointer;
   outline: none;
   padding: 5px 10px;
}

.selected {
   background-color: #009ad0;
   border: 3px solid #009ad0;
   border-radius: 50px;
   color: white;
   font-size: 1.2em;
   cursor: pointer;
   outline: none;
   padding: 5px 10px;
}

.contentButton {
   width: 100%;
   align-items: center;
   display: flex;
   justify-content: center;
   margin-bottom: 15px;
   > button:not(:last-child) {
      margin-right: 10px;
   }
}

.containerFormCortesy {
   margin-top: 15px;
   text-align: center;
}

.UserAutocomplete {
   margin: auto;
}

.contentInput {
   margin: 15px 0;
}

.input {
   width: 50%;
}

.containerForm {
   margin-top: 15px;
}

.containerAutoCompleteUser {
}

.containerGetContent {
   text-align: center;
}

@media (max-width: 600px) {
   .contentButton {
      width: 80%;
      flex-direction: column;
      justify-content: none;
   }

   button {
      margin: 5px;
   }
}
