.createUser {
   &-content-modal {
      display: flex;
      flex-direction: column;
      height: 100%;
   }

   &-actions-modal {
      .actions-item:not(:first-child) {
         margin-left: 15px;
      }
   }
   &-inputs {
      margin-bottom: 15px !important;
   }
}
