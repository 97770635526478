.ReportContent {
   display: flex;
   flex-direction: column;
   width: 100%;
   height: 100%;
   // overflow-x: auto;

   &-chart {
      flex-direction: row;
      justify-content: space-between;

      * > {
         flex: 1;
      }
   }
}
.FilterContainer {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   padding: 5px 0;

   > * {
      min-width: 200px !important;

      &:not(:last-child) {
         margin-right: 10px;
      }
   }
}

.titulo {
   margin: auto;
   width: 90%;
   height: 5vh;
}

.containerCard {
   width: 100%;
   height: 100%;
   margin: auto;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
}

.card {
   width: 25%;
   margin: 0 10px;
   text-decoration: none;
   flex: 1 0 25%;
}

.tituloCard {
   width: 100%;
   // font-style: italic;
   text-align: center;
   font-weight: bold;
}

.icono {
   margin: 15px 0;
   font-size: 5em;
   width: 100%;
   color: rgb(0, 62, 85);
   text-align: center;
}

.icono-image {
   margin: 15px 0;
   font-size: 5em;
   width: 74px;
   color: rgb(0, 62, 85);
   text-align: center;
}

.descriptionCard {
   font-size: 14px;
   font-style: italic;
   color: #646464;
   text-align: center;
}

@media (max-width: 600px) {
   .containerCard {
      flex-direction: column;
      //    margin: 50px auto;
      //    width: 100%;
   }

   .card {
      width: 80%;
      margin: 15px 0;
   }
}
