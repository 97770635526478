.DefaultLayout {
   width: 100vw;
   height: 100vh;
   background: rgb(0, 62, 85);
   background: -moz-linear-gradient(
      150deg,
      rgba(0, 62, 85, 1) 0%,
      rgba(40, 87, 101, 1) 52%,
      rgba(112, 127, 127, 1) 100%
   );
   background: -webkit-linear-gradient(
      150deg,
      rgba(0, 62, 85, 1) 0%,
      rgba(40, 87, 101, 1) 52%,
      rgba(112, 127, 127, 1) 100%
   );
   background: linear-gradient(
      150deg,
      rgba(0, 62, 85, 1) 0%,
      rgba(40, 87, 101, 1) 52%,
      rgba(112, 127, 127, 1) 100%
   );
   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#003e55",endColorstr="#707f7f",GradientType=1);

   &-drawer {
      grid-area: drawer;
      place-items: unset;
      width: 90px;
      height: 100vh;

      transition: width 600ms ease-in-out;
   }

   &-main {
      width: 100%;
      grid-area: main;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      background-color: white;
      -webkit-box-shadow: -5px 4px 26px -9px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: -5px 4px 26px -9px rgba(0, 0, 0, 0.75);
      box-shadow: -5px 4px 26px -9px rgba(0, 0, 0, 0.75);
      transition: transform 600ms ease-in-out;
   }

   &-container {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      grid-template-areas: 'drawer main';
      width: 100%;
      height: 100%;
      overflow: hidden;

      &.open-drawer {
         .DefaultLayout-drawer {
            width: 300px;
         }
         .DefaultLayout-main {
            transform: scale(0.95) translateX(50px);
         }
      }
   }
}
