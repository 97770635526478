.ClubPage {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100%;
}


.items-towers {
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   gap: 10px;
}


.items {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   height: 300px;
   border-radius: 0.25rem;
   box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
   padding: 1rem;
   background: linear-gradient(to bottom left, #f0ecec 40%, #fbf6f3 100%);

   >img {
      height: 200px !important;
      width: 200px !important;
      object-fit: fill;
      border-radius: 2px;
   }

   >.footer-card {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      row-gap: 5px;
      column-gap: 10px;
   }
}